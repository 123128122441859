import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import brand_logo from '../../assets/images/matrix logo-01.png';

const Header = () => {
  return (
    <Navbar collapseOnSelect variant='dark' expand='lg' sticky='top' style={{ backgroundColor: '#040404' }}>
      <Navbar.Brand as={Link} to='/' eventKey='root'>
        <img className='img-fluid ms-3 me-3' src={brand_logo} alt='brand_logo' style={{ height: '50px' }} /> Matrix
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='m-auto'>
          <Nav.Link as={Link} to='/home' eventKey='home' className='me-4'>
            Who We Are
          </Nav.Link>
          <NavDropdown title='Ecosystem' id='basic-nav-dropdown' className='me-4'>
            <NavDropdown.Item as={Link} to='/ecosystems/edStructure' eventKey='edStructure'>
              EdStructure
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to='/ecosystems/guide' eventKey='guide'>
              Guide
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to='/ecosystems/capeComm' eventKey='capeComm'>
              CapeComm
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to='/goals' eventKey='goals' className='me-4'>
            Goals
          </Nav.Link>
          <Nav.Link as={Link} to='/community' eventKey='community' className='me-4'>
            Community
          </Nav.Link>
          <Nav.Link as={Link} to='/resources' eventKey='resources' className='me-4'>
            Resource
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
