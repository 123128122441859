import React, { useState, useEffect } from 'react';

// banner style
import '../../styles/home/banner.scss';

// Guide Page components
import Banner from '../../components/guide/Banner';
import Categories from '../../components/guide/Categories';
import Colab from '../../components/guide/Colab';
import Demo from '../../components/guide/Demo';
import FeaturedNews from '../../components/guide/FeaturedNews';
import Features from '../../components/guide/Features';
import Instructor from '../../components/guide/Instructor';
import Reviews from '../../components/guide/Reviews';

const baseURL = 'https://admin.matrixworld.info';

const Guide = () => {
  const [guide, setGuide] = useState({});
  useEffect(() => {
    /**
     * Get guide data
     * @param {string} baseURL
     * @param {string} endpoint
     * @return {object} guide
     */
    fetch(`${baseURL}/guide_details/`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then((data) => {
        setGuide(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <Banner slider={guide?.data?.slider[0]?.image} />
      <Colab collaboration={guide?.data?.collaboration} />
      <FeaturedNews learning_xp={guide?.data?.learning_experience} />
      <Categories courses={guide?.data?.course} />
      <Features standardLearning={guide?.data?.standard_learning} />
      <Demo preview={guide?.data?.preview} />
      <Instructor instructor={guide?.data?.instructor} />
      <Reviews student={guide?.data?.student} />
    </>
  );
};

export default Guide;
