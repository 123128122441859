import React from "react";

//lazy loader for images
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Banner = ({ slider, baseURL }) => {
  return (
    <>
      <LazyLoadImage
        src={`${slider}`}
        alt="banner"
        className="top_banner"
        height={`${slider}`.height}
        width="100%"
      />
    </>
  );
};

export default Banner;
