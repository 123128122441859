import React, { useState, useEffect } from 'react';

// stylesheet
import '../../styles/home/banner.scss';

// EdStructure Page components
import Banner from '../../components/edStructure/Banner';
import Intro from '../../components/edStructure/Intro';
import Preview from '../../components/edStructure/Preview';
import Solutions from '../../components/edStructure/Solutions';

// API baseURL
const baseURL = 'https://admin.matrixworld.info';

const EdStructure = () => {
  const [edStructure, setEdStructure] = useState({});
  useEffect(() => {
    /**
     * Get edStructure data
     * @param {string} baseURL
     * @param {string} endpoint
     * @return {object} edStructure
     */
    fetch(`${baseURL}/ed_structure_details/`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then((data) => {
        console.log(data);
        setEdStructure(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Banner slider={edStructure?.data?.slider[0]} baseURL={baseURL} />
      <Intro edstructure={edStructure?.data?.edstructure} baseURL={baseURL} />
      <Solutions solution={edStructure?.data?.solution[0]} baseURL={baseURL} />
      <Preview preview={edStructure?.data?.preview} baseURL={baseURL} />
    </>
  );
};

export default EdStructure;
