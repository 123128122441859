import React from 'react';
import { Button } from 'react-bootstrap';

const Preview = ({ preview }) => {
  return (
    <div className='my-5 text-center'>
      <p>{preview?.description}</p>
      <h2 className='fw-bold'>{preview?.title}</h2>
      <Button as='a' href='https://edstructure.matrixworld.info/' target='_blank' rel='noreferrer noopener' variant='primary' className='my-5 ps-5 pe-5 pt-3 pb-3'>
        {preview?.button_text}
      </Button>
    </div>
  );
};

export default Preview;
