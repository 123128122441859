import React, { useEffect, useState, Suspense, lazy } from "react";

// Home page components (Banner, Vision, Products, News)
// import Banner from '../components/home/Banner';
import News from "../components/home/News";
import Products from "../components/home/Products";
import Vision from "../components/home/Vision";

// imported Spinner from react-loader-Spinner
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Triangle } from "react-loader-spinner";

// API baseURL
const baseURL = "https://admin.matrixworld.info";

//Lazy loader - imported Banner
const Banner = lazy(() => import("../components/home/Banner"));

const Home = () => {
  const [whoWeAre, setwhoWeAre] = useState({});

  useEffect(() => {
    /**
     * Get who we are data
     * @constructor
     * @param {string} baseURL
     * @param {string} endpoint
     * @return {object} whoWeAre
     */
    fetch(`${baseURL}/who_we_are_details/`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setwhoWeAre(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <Suspense
        fallback={
          <div
            style={{ height: "80vh", width: "100%" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Triangle color="#237bb3" height={80} width={80} />
            Please wait... <br />
            Video is Loading...
          </div>
        }
      >
        <Banner slider={whoWeAre} baseURL={baseURL} />
      </Suspense>
      <Vision vision={whoWeAre} />
      <Products products={whoWeAre} />
      <News newsUpdate={whoWeAre} />
    </>
  );
};

export default Home;
