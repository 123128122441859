import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "../../styles/community/Clients.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Clients = ({ goodIdea }) => {
  return (
    <Container id="clients">
      <div className="text-center">
        <h2 className="mt-5">{goodIdea?.title}</h2>
        <p className="my-4">{goodIdea?.title}</p>
      </div>
      <Row className="my-5 py-5 text-center gy-5">
        {goodIdea?.good_idea_detail?.map(
          (
            { id, name, image, designation, description, button_text },
            index
          ) => (
            <Col md={4} key={id} className="my-5">
              <Card className="shadow h-100 pt-5">
                <LazyLoadImage variant="top" src={image} roundedCircle />
                <Card.Body>
                  <Card.Text as="h6" className="my-5">
                    {description}
                  </Card.Text>
                  <Card.Title as="h5" className="fw-bold">
                    {name}
                  </Card.Title>
                  <Card.Text as="p">{designation}</Card.Text>
                  <Button variant="primary" className="my-4 px-4">
                    {button_text}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          )
        )}
      </Row>
    </Container>
  );
};

export default Clients;
