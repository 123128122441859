import React from "react";

// imported react-bootstrap styled components
import { Container, Row, Col } from "react-bootstrap";

//lazy load
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Colab = ({ collaboration }) => {
  return (
    <Container className="my-5 py-5">
      <div className="text-center">
        <p>{collaboration?.description}</p>
      </div>
      <Row className="my-5 g-4">
        {collaboration?.collaboration_list?.map(({ id, title, image }) => (
          <Col md={4} key={id}>
            <LazyLoadImage
              src={image}
              alt={title}
              className="img-fluid"
              height={image.height}
              width="100%"
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Colab;
