import React from "react";

// imported react-bootstrap components
import { Container, Row, Col, Card } from "react-bootstrap";

//lazy loader
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Categories = ({ courses }) => {
  return (
    <Container className="my-5">
      <div className="text-center my-5">
        <h2 className="py-3">{courses?.title}</h2>
        <p>{courses?.description}</p>
      </div>
      <Row xs={1} md={3} className="g-4">
        {courses?.course_description?.map(
          ({ id, image, title, description }) => (
            <Col key={id}>
              <Card className="border-0">
                <LazyLoadImage
                  variant="top"
                  src={image}
                  height={image.height}
                  width="100%"
                  style={{ borderRadius: "10px" }}
                />
                <Card.Body className="ps-0">
                  <Card.Title as="h6" className="fw-bold">
                    {title}
                  </Card.Title>
                  <Card.Text as="p">{description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          )
        )}
      </Row>
    </Container>
  );
};

export default Categories;
