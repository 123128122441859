import React from 'react';

// imported react-router-dom components
import { Routes, Route } from 'react-router-dom';

// global css file
import './styles/App.scss';

// imported common components
import Header from './components/common/Header';
import Footer from './components/common/Footer';

// imported pages
import Home from './pages/Home';
import Community from './pages/Community';
import EdStructure from './pages/Ecosystems/EdStructure';
import Guide from './pages/Ecosystems/Guide';
import CapeComm from './pages/Ecosystems/CapeComm';
import Goals from './pages/Goals';
import Resources from './pages/Resources';

// imported 404 Error Page
import NotFound from './pages/NotFound';

const App = () => {
  return (
    <>
      {/* common component - Header */}
      <Header />
      <Routes>
        {/* Root Route */}
        <Route path='/' element={<Home />} />
        <Route path='home' element={<Home />} />
        {/* Ecosystems Route */}
        <Route path='ecosystems/edStructure' element={<EdStructure />} />
        <Route path='ecosystems/guide' element={<Guide />} />
        <Route path='ecosystems/capeComm' element={<CapeComm />} />
        <Route path='community' element={<Community />} />
        <Route path='goals' element={<Goals />} />
        <Route path='resources' element={<Resources />} />
        {/* 404 Error Page */}
        <Route path='*' element={<NotFound />} />
      </Routes>
      {/* common component - Footer */}
      <Footer />
    </>
  );
};

export default App;
