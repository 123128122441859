import React from "react";

// react-bootstrap components
import { Container, Row, Col, Card, Button } from "react-bootstrap";

// stylesheet
import "../../styles/community/Communities.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Communities = ({ businessWay }) => {
  return (
    <Container id="communities" className="my-5">
      <div className="text-center">
        <h2 className="mt-5">{businessWay?.title}</h2>
        <p className="my-4">{businessWay?.description}</p>
      </div>
      <Row className="my-5 py-5 g-4">
        {businessWay?.business_way_description?.map(
          ({ id, image, title, description, button_text }) => (
            <Col key={id} md={4}>
              <Card className="shadow h-100">
                <LazyLoadImage variant="top" src={image} roundedCircle={true} />
                <Card.Body>
                  <Card.Title as="h5" className="my-4 fw-bold">
                    {title}
                  </Card.Title>
                  <Card.Text as="h6">{description}</Card.Text>
                </Card.Body>
                <Card.Footer className="border-0 bg-white">
                  <Button variant="primary" className="my-4 px-4">
                    {button_text}
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          )
        )}
      </Row>
    </Container>
  );
};

export default Communities;
