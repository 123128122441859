import React, { useState, useEffect } from 'react';

// imported community page sections
import Clients from '../components/community/Clients';
import Communities from '../components/community/Communities';
import CommunityBanner from '../components/community/CommunityBanner';
import Voice from '../components/community/Voice';
import News from '../components/community/News';

// API baseURL
const baseURL = 'https://admin.matrixworld.info';

const Community = () => {
  const [community, setCommunity] = useState({});

  /**
   * Get community data
   * @constructor
   * @param {string} baseURL
   * @param {string} endpoint
   * @return {object} community
   */
  useEffect(() => {
    fetch(`${baseURL}/community_details/`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then((data) => {
        setCommunity(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <CommunityBanner banner={community?.data?.slider[0]?.image} />
      <Communities businessWay={community?.data?.business_way} />
      <Clients goodIdea={community?.data?.good_idea} />
      <Voice communityVoice={community?.data?.community_voice} />
      <News newsList={community?.data?.news_lists} />
    </>
  );
};

export default Community;
