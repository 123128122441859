import React from "react";
import { Image } from "react-bootstrap";

//lazy loader for images
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// stylesheet
import "../../styles/App.scss";

const Banner = ({ slider }) => {
  return (
    <LazyLoadImage
      className="top_banner mb-3"
      src={`${slider?.image}`}
      alt="banner"
      height={`${slider?.image}`.height}
      width="100%"
    />
  );
};

export default Banner;
