import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Voice = ({ communityVoice }) => {
  return (
    <Container id="voice" className="my-5">
      <div className="text-center">
        <h2 className="mt-5">{communityVoice?.title}</h2>
        <p className="my-4">{communityVoice?.description}</p>
      </div>
      <Row className="my-5 g-4">
        {communityVoice?.community_voice_detail?.map(
          ({ id, icon, name, description, button_text }) => (
            <Col md={6}>
              <Card className="p-4 shadow">
                <Row>
                  <Col sm={3} md={3}>
                    <LazyLoadImage src={icon} alt="logo" fluid />
                  </Col>
                  <Col sm={9} md={9}>
                    <h5>{name}</h5>
                    <h6 className="my-4">{description}</h6>
                    <Button>{button_text}</Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          )
        )}
      </Row>
    </Container>
  );
};

export default Voice;
