import React from "react";

//imported react-bootstrap components
import { Container, Row, Col, Card } from "react-bootstrap";

//lazy loader
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Instructor = ({ instructor }) => {
  return (
    <Container className="my-5">
      <div className="text-center my-5">
        <h2 className="py-3">{instructor?.title}</h2>
        <p>{instructor?.description}</p>
      </div>
      <Row className="g-4">
        {instructor?.instructor_description?.map(
          ({ id, title, designation, image }, index) => (
            <Col md={6}>
              <Card className="shadow border-0">
                <Row className="d-flex align-items-center justify-content-center h-100">
                  <Col>
                    <LazyLoadImage
                      src={image}
                      alt="image"
                      style={{ height: "350px", objectFit: "cover" }}
                    />
                  </Col>
                  <Col className="text-center p-3">
                    <Card.Title as="h6" className="fw-bold">
                      {title}
                    </Card.Title>
                    <Card.Text as="p" className="small">
                      {designation}
                    </Card.Text>
                    <div>
                      <LazyLoadImage
                        className="social-icon"
                        src="https://img.icons8.com/ios-filled/50/000000/facebook-new.png"
                        alt="fb_logo"
                      />
                      <LazyLoadImage
                        className="social-icon"
                        src="https://img.icons8.com/ios-filled/50/000000/twitter-circled.png"
                        alt="twitter_logo"
                      />
                      <LazyLoadImage
                        className="social-icon"
                        src="https://img.icons8.com/ios-glyphs/60/000000/instagram-circle.png"
                        alt="instagram_logo"
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          )
        )}
      </Row>
    </Container>
  );
};

export default Instructor;
