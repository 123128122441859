import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
// imported icons from react-icons
import {
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  // BiLogInCircle,
  FaLongArrowAltRight,
} from 'react-icons/fa';
import { CgLogIn } from 'react-icons/cg';

const Footer = () => {
  return (
    <footer className='py-3 text-white' style={{ backgroundColor: '#040404' }}>
      <Container className='my-3'>
        <Row className='mx-5 px-5 g-4'>
          {/* start ecosystem column */}
          <Col md={5}>
            <h5>Ecosystem</h5>
            <ul>
              <li>
                <Link to='ecosystems/edStructure' className='link'>
                  EdStructure
                </Link>
              </li>
              <li>
                <Link to='ecosystems/guide' className='link'>
                  Guide
                </Link>
              </li>
              <li>
                <Link to='ecosystems/capeComm' className='link'>
                  CapeComm
                </Link>
              </li>
            </ul>
          </Col>
          {/* end ecosystem column */}

          {/* start resources column */}
          <Col md={5}>
            <h5>Resources</h5>
            <ul>
              <li>
                <a href='/resources/#help-center' className='link'>
                  Help Center
                </a>
              </li>
              <li>
                <Link to='/resources/#job-seekers' className='link'>
                  For Job Seekers
                </Link>
              </li>
              <li>
                <Link to='/resources/#educators' className='link'>
                  For Educators
                </Link>
              </li>
            </ul>
          </Col>
          {/* end resources column */}

          {/* start social media column */}
          <Col md={2}>
            <h5>Follow Us</h5>
            <ul>
              <li>
                <a className='link' href='#' target='_blank' rel='noopener noreferrer'>
                  <FaLinkedinIn className='me-3' /> LinkedIn
                </a>
              </li>
              <li>
                <a className='link' href='#' target='_blank' rel='noopener noreferrer'>
                  <FaFacebookF className='me-3' /> Facebook
                </a>
              </li>
              <li>
                <a className='link' href='#' target='_blank' rel='noopener noreferrer'>
                  <FaInstagram className='me-3' /> Instagram
                </a>
              </li>
              <li>
                <a className='link' target='_blank' href='https://admin.matrixworld.info/account/login/'>
                  <CgLogIn className='me-3' />
                  Official
                </a>
              </li>
            </ul>
          </Col>
          {/* end social media column */}
        </Row>

        {/* copyright section */}
        <p className='text-center text-white mt-5 mb-3 small' style={{ fontSize: '14px !important' }}>
          Copyright &copy; 2021. Matrix World. All rights reserved.
        </p>
        {/* end copyright section */}
      </Container>
    </footer>
  );
};

export default Footer;
