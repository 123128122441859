import React from 'react';

const NotFound = () => {
  /**
   * 404 page
   */
  return (
    <div>
      <h1>Error 404 | Not Found</h1>
    </div>
  );
};

export default NotFound;
