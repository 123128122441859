import React from 'react';

// News style
import '../../styles/home/News.scss';

// imported react-bootstrap components
import { Container, Row } from 'react-bootstrap';

import NewsCard from '../common/NewsCard';

const News = ({ newsUpdate }) => {
  return (
    <Container className='text-center py-3'>
      <h2 className='mb-5 pb-5'>Latest News</h2>
      <Row className='g-4'>
        {newsUpdate?.data?.news?.map((news, index) => (
          <NewsCard key={index} news={news} />
        ))}
      </Row>
    </Container>
  );
};

export default News;
