import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Reviews = ({ student }) => {
  return (
    <Container className='my-5 py-5'>
      <div className='text-center my-5'>
        <h2 className='py-3'>{student?.title}</h2>
        <p>{student?.description}</p>
      </div>
      <Row className='g-4'>
        {student?.student_comment?.map(({ id, image, title, designation, comment }) => (
          <Col md={4}>
            <Card className='student-review h-100'>
              <Card.Header className='border-0 m-0'>
                <Row className='d-flex align-items-center'>
                  <Col xs={3} md={3}>
                    <Card.Img src='https://img.icons8.com/ios-filled/50/000000/user-male-circle.png' alt='student_pic' />
                  </Col>
                  <Col xs={6} md={6} className='p-0'>
                    <div>
                      <Card.Title as='h6'>{title}</Card.Title>
                      <Card.Text as='p' className='small'>
                        {designation}
                      </Card.Text>
                    </div>
                  </Col>
                  <Col xs={3} md={3} className='p-0'>
                    <div className='text-center'>
                      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M7.46176 0.550632L5.43129 4.70529L0.839307 5.36128C0.0271188 5.48624 -0.285261 6.48585 0.308261 7.07938L3.58825 10.2969L2.8073 14.8264C2.68235 15.6386 3.55701 16.2633 4.27549 15.8885L8.36767 13.7331L12.4286 15.8885C13.1471 16.2633 14.0217 15.6386 13.8968 14.8264L13.1158 10.2969L16.3958 7.07938C16.9894 6.48585 16.677 5.48624 15.8648 5.36128L11.304 4.70529L9.24233 0.550632C8.89871 -0.167842 7.83662 -0.19908 7.46176 0.550632Z' fill='#0F43F9' />
                      </svg>
                      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M7.46176 0.550632L5.43129 4.70529L0.839307 5.36128C0.0271188 5.48624 -0.285261 6.48585 0.308261 7.07938L3.58825 10.2969L2.8073 14.8264C2.68235 15.6386 3.55701 16.2633 4.27549 15.8885L8.36767 13.7331L12.4286 15.8885C13.1471 16.2633 14.0217 15.6386 13.8968 14.8264L13.1158 10.2969L16.3958 7.07938C16.9894 6.48585 16.677 5.48624 15.8648 5.36128L11.304 4.70529L9.24233 0.550632C8.89871 -0.167842 7.83662 -0.19908 7.46176 0.550632Z' fill='#0F43F9' />
                      </svg>
                      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M7.46176 0.550632L5.43129 4.70529L0.839307 5.36128C0.0271188 5.48624 -0.285261 6.48585 0.308261 7.07938L3.58825 10.2969L2.8073 14.8264C2.68235 15.6386 3.55701 16.2633 4.27549 15.8885L8.36767 13.7331L12.4286 15.8885C13.1471 16.2633 14.0217 15.6386 13.8968 14.8264L13.1158 10.2969L16.3958 7.07938C16.9894 6.48585 16.677 5.48624 15.8648 5.36128L11.304 4.70529L9.24233 0.550632C8.89871 -0.167842 7.83662 -0.19908 7.46176 0.550632Z' fill='#0F43F9' />
                      </svg>
                      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M7.46176 0.550632L5.43129 4.70529L0.839307 5.36128C0.0271188 5.48624 -0.285261 6.48585 0.308261 7.07938L3.58825 10.2969L2.8073 14.8264C2.68235 15.6386 3.55701 16.2633 4.27549 15.8885L8.36767 13.7331L12.4286 15.8885C13.1471 16.2633 14.0217 15.6386 13.8968 14.8264L13.1158 10.2969L16.3958 7.07938C16.9894 6.48585 16.677 5.48624 15.8648 5.36128L11.304 4.70529L9.24233 0.550632C8.89871 -0.167842 7.83662 -0.19908 7.46176 0.550632Z' fill='#0F43F9' />
                      </svg>
                      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M7.46176 0.550632L5.43129 4.70529L0.839307 5.36128C0.0271188 5.48624 -0.285261 6.48585 0.308261 7.07938L3.58825 10.2969L2.8073 14.8264C2.68235 15.6386 3.55701 16.2633 4.27549 15.8885L8.36767 13.7331L12.4286 15.8885C13.1471 16.2633 14.0217 15.6386 13.8968 14.8264L13.1158 10.2969L16.3958 7.07938C16.9894 6.48585 16.677 5.48624 15.8648 5.36128L11.304 4.70529L9.24233 0.550632C8.89871 -0.167842 7.83662 -0.19908 7.46176 0.550632Z' fill='#0F43F9' />
                      </svg>
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Card.Text as='p'>{comment}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Reviews;
