import React, { useState, useEffect } from 'react';

// react-bootstrap components
import { Container, Image, Button, Row, Col, Card } from 'react-bootstrap';

// banner style
import '../../styles/home/banner.scss';

// API baseURL
const baseURL = 'https://admin.matrixworld.info';

const CapeComm = () => {
  const [capecomm, setCapeComm] = useState({});

  useEffect(() => {
    /**
     * Get capecomm data
     * @constructor
     * @param {string} baseURL
     * @param {string} endpoint
     * @return {object} capecomm
     */
    fetch(`${baseURL}/capecomm_details/`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then((data) => {
        setCapeComm(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      {/* start banner section */}
      <div>
        <Image src={capecomm?.data?.slider[0]?.image} alt='banner' className='top_banner' />
      </div>
      {/* end banner section */}

      <Container>
        {/* start Easy Step section */}
        <div className='my-5 text-center'>
          <h6 className='fw-bold' style={{ color: '#D21F59' }}>
            {capecomm?.data?.follow_step?.title}
          </h6>
          <h3 className='fw-bold' style={{ color: '#D21F59' }}>
            {capecomm?.data?.follow_step?.sub_title}
          </h3>
          <p>{capecomm?.data?.follow_step?.description}</p>
        </div>
        <Row className='my-5 g-4 d-flex justify-content-between'>
          {capecomm?.data?.follow_step?.follow_step?.map(({ id, image, title, description }) => (
            <Col key={id} md={3}>
              <Card className='border-0'>
                <Card.Img variant='top' src={image} style={{ borderRadius: '10px' }} />
                <Card.Body className='p-0'>
                  <Card.Title as='h5' className='my-3'>
                    {title}
                  </Card.Title>
                  <Card.Text as='p'>{description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      {/* end Easy Step section */}

      {/* start Find the Right Job section */}
      <Container className='my-5'>
        <div className='text-center my-5 py-5'>
          <h3 style={{ color: '#D21F59' }}>{capecomm?.data?.find_job?.title}</h3>
          <p>{capecomm?.data?.find_job?.description}</p>
        </div>
        <Row xs={1} md={3} className='g-4'>
          {capecomm?.data?.find_job?.find_job_description?.map(({ id, title, description }) => (
            <Col key={id}>
              <Row className='g-4'>
                <Col md={2}>
                  <svg width='53' height='53' viewBox='0 0 53 53' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M46.875 0.25H5.625C2.46094 0.25 0 2.82812 0 5.875V47.125C0 50.2891 2.46094 52.75 5.625 52.75H46.875C49.9219 52.75 52.5 50.2891 52.5 47.125V5.875C52.5 2.82812 49.9219 0.25 46.875 0.25Z' fill='#D21F59' />
                  </svg>
                </Col>
                <Col md={10}>
                  <h6 className='fw-bold'>{title}</h6>
                  <p className='small'>{description}</p>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Container>
      {/* end Find the Right Job section */}

      {/* start Demo section */}
      <div style={{ background: '#D21F59' }}>
        <Container className='py-5'>
          <Row className='my-5 g-4'>
            <Col md={6}>
              <img src={capecomm?.data?.connection_messages?.image} alt='' className='img-fluid' />
            </Col>
            <Col className='d-flex align-items-center'>
              <div className='text-white'>
                <h3>{capecomm?.data?.connection_messages?.title}</h3>
                <p className='my-4'>{capecomm?.data?.connection_messages?.description}</p>
                <Button style={{ backgroundColor: '#FBFAFC' }}>
                  <span style={{ color: '#0F43F9' }}>{capecomm?.data?.connection_messages?.button_text}</span>
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* end Demo section */}

      {/* start Job Feed section */}
      <Container className='my-5'>
        <div className='text-center mt-5 pt-3'>
          <h2 style={{ color: '#D21F59' }}>{capecomm?.data?.web_preview?.title}</h2>
          <p>{capecomm?.data?.web_preview?.description}</p>
        </div>
        <Image src={capecomm?.data?.web_preview?.image} alt='CapeComm' className='w-100' />
      </Container>
      {/* end Job Feed section */}
    </>
  );
};

export default CapeComm;
