import React from "react";
import { Container, Button, Row, Col } from "react-bootstrap";

//lazy loader for images
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Intro = ({ edstructure, baseURL }) => {
  return (
    <Container className="my-5 py-5">
      <Row className="product d-flex align-items-center flex-sm-row-reverse g-4">
        <Col md={6}>
          <LazyLoadImage
            src={`${edstructure?.image}`}
            alt="EdStructure"
            fluid
            height={`${edstructure?.image}`.height}
            width="100%"
          />
        </Col>
        <Col md={6}>
          <h3 className="fw-bold">{edstructure?.title}</h3>
          <p className="my-4">{edstructure?.description}</p>
          <Button>{edstructure?.button_text}</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Intro;
