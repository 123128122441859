import React from 'react';

// imported react-bootstrap components
import { Container, Button } from 'react-bootstrap';

const Demo = ({ preview }) => {
  return (
    <Container className='text-center py-5'>
      <h2>{preview?.title}</h2>
      <p>{preview?.description}</p>
      <Button as='a' href='http://guide.matrixworld.info/' target='_blank' rel='noreferrer noopener' variant='primary' className='my-5 ps-5 pe-5 pt-3 pb-3'>
        {preview?.button_text}
      </Button>
    </Container>
  );
};

export default Demo;
