import React, { useState, useEffect } from "react";

// react-bootstrap styled components
import { Container, Row, Col, Card } from "react-bootstrap";

// banner style
import "../styles/home/banner.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const baseURL = "https://admin.matrixworld.info";

const Goals = () => {
  const [goals, setGoals] = useState({});

  useEffect(() => {
    /**
     * goals api
     * @constructor
     * @param {string} baseURL
     * @param {string} endpoint
     * @return {object} goals
     */
    fetch(`${baseURL}/goals_details/`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setGoals(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      {/* start Goals Banner */}
      <LazyLoadImage
        src={goals?.data?.slider[0]?.image}
        alt="banner"
        className="top_banner"
      />
      {/* end Goals Banner */}

      <Container className="my-5">
        {/* start Our Mission section */}
        <div>
          <div className="text-center">
            <h2 className="mt-5">{goals?.data?.mission?.title}</h2>
            <p className="my-4">{goals?.data?.mission?.description}</p>
          </div>
          <Row className="my-5 g-4 text-center">
            {goals?.data?.mission?.mission_description?.map(
              ({ id, icon, title, description }) => (
                <Col key={id} md={4}>
                  <Card className="p-4 shadow h-100">
                    <LazyLoadImage
                      src={icon}
                      alt="icon"
                      style={{ height: "50px", width: "50px", margin: "auto" }}
                    />
                    <Card.Body>
                      <Card.Title as="h5" className="my-4 fw-bold">
                        {title}
                      </Card.Title>
                      <Card.Text as="p">{description}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              )
            )}
          </Row>
        </div>
        {/* end Our Mission section */}

        {/* start Our Values section */}
        <div className="py-5">
          <div className="text-center">
            <h2 className="my-3">{goals?.data?.value?.title}</h2>
            <p>{goals?.data?.value?.description}</p>
          </div>
          <Row xs={1} md={3} className="mb-5 g-4">
            {goals?.data?.value?.value_description?.map(
              ({ id, title, description }) => (
                <Col key={id}>
                  <Card className="p-4 shadow border-0 h-100">
                    <Card.Body>
                      <Card.Title as="h5" className="mb-4 fw-bold">
                        {title}
                      </Card.Title>
                      <Card.Text as="p">{description}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              )
            )}
          </Row>
        </div>
        {/* end Our Values section */}
      </Container>
    </>
  );
};

export default Goals;
