import React from "react";

// imported react-bootstrap styled components
import { Container, Row, Col, Image } from "react-bootstrap";

//image laze loader
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Vision = ({ vision }) => {
  return (
    <Container className="text-center my-5 py-3">
      <div className="text-center">
        <h2>{vision?.data?.vision.title}</h2>
        <h4 className="my-4">{vision?.data?.vision.short_description}</h4>
      </div>
      <Row className="m-5 g-5">
        {/* start vision details section */}
        {vision?.data?.vision?.vision_details.map(
          ({ id, title, description, icon }) => (
            <Col key={id} md={4}>
              <LazyLoadImage
                src={`${icon}`}
                alt="vision-icon"
                style={{ height: "50px", width: "50px" }}
              />
              <h5 className="my-4">{title}</h5>
              <p>{description}</p>
            </Col>
          )
        )}
        {/* end vision details section */}
      </Row>
    </Container>
  );
};

export default Vision;
