import React, { useState } from "react";

import { Col, Card } from "react-bootstrap";

//image lazy loader
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const NewsCard = ({ news }) => {
  const [readMore, setReadMore] = useState(true); // read more state for controlling read more button
  return (
    <Col md={3}>
      <Card className="h-100 border-0">
        <LazyLoadImage
          className=" w-100"
          src={news?.image}
          alt="thumbnail"
          style={{ height: "250px", objectFit: "cover" }}
        />
        <Card.Body>
          <Card.Title as="h6" className="fw-bold">
            {news?.title}
          </Card.Title>
          <span>
            {readMore ? news?.description?.substring(0, 80) : news?.description}
          </span>
        </Card.Body>
        <Card.Footer className="border-0">
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => setReadMore(!readMore)}
          >
            {readMore ? "Read more" : "Read less"}
          </span>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default NewsCard;
