import React from "react";

// imported react-bootstrap styled components
import { Container, Row, Col } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Features = ({ standardLearning }) => {
  return (
    <Container className="my-5">
      <div className="text-center my-5">
        <h2 className="py-3">{standardLearning?.title}</h2>
        <p>{standardLearning?.description}</p>
      </div>
      <Row className='d-flex align-items-center'>
        {/* start left portion */}
        <Col md={2}>
          {standardLearning?.standard_learning_description?.map(
            ({ id, title, description }, index) => {
              if (index % 2 === 0) {
                return (
                  <div key={id} className="py-3">
                    <svg
                      width="32"
                      height="33"
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.125 0.75H3.375C1.47656 0.75 0 2.29688 0 4.125V28.875C0 30.7734 1.47656 32.25 3.375 32.25H28.125C29.9531 32.25 31.5 30.7734 31.5 28.875V4.125C31.5 2.29688 29.9531 0.75 28.125 0.75Z"
                        fill="#752368"
                      />
                    </svg>
                    <h6 className="my-3 fw-bold">{title}</h6>
                    <p className="small">{description}</p>
                  </div>
                );
              }
            }
          )}
        </Col>
        {/* end left portion */}

        {/* start mid portion */}
        <Col
          md={8}
          className="d-flex align-items-center justify-content-center p-5"
        >
          <LazyLoadImage
            className="w-100"
            src={standardLearning?.image}
            alt="Guide PC"
            fluid
          />
        </Col>
        {/* end mid portion */}

        {/* start right portion */}
        <Col md={2} className="text-end">
          {standardLearning?.standard_learning_description?.map(
            ({ id, title, description }, index) => {
              if (index % 2 !== 0) {
                return (
                  <div key={id} className="py-3">
                    <svg
                      width="32"
                      height="33"
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.125 0.75H3.375C1.47656 0.75 0 2.29688 0 4.125V28.875C0 30.7734 1.47656 32.25 3.375 32.25H28.125C29.9531 32.25 31.5 30.7734 31.5 28.875V4.125C31.5 2.29688 29.9531 0.75 28.125 0.75Z"
                        fill="#752368"
                      />
                    </svg>
                    <h6 className="my-3 fw-bold">{title}</h6>
                    <p className="small">{description}</p>
                  </div>
                );
              }
            }
          )}
        </Col>
        {/* end right portion */}
      </Row>
    </Container>
  );
};

export default Features;
