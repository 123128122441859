import React, { useState } from "react";

// product component style
import "../../styles/home/product.scss";

// imported react-bootstrap styled components
import { Container, Row, Col, Button, Image } from "react-bootstrap";

//image laze loader
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Products = ({ products }) => {
  const [readMore, setReadMore] = useState(true);

  return (
    <Container className="text-center my-3">
      <div className="text-center">
        <h2>{products?.data?.building_the_matrix.title}</h2>
        <h5 className="my-4">
          {products?.data?.building_the_matrix.short_description}
        </h5>
      </div>

      {products?.data?.building_the_matrix?.building_the_matrix_details.map(
        ({ title, description, image, button_text }, index) => {
          if (index % 2 === 0) {
            return (
              <Row className="product my-5 py-3 d-flex align-items-center flex-sm-row-reverse g-4">
                <Col md={6}>
                  <LazyLoadImage
                    src={`${image}`}
                    alt="EdStructure"
                    fluid
                    height={`${image}`.height}
                    width="100%"
                  />
                </Col>
                <Col md={6}>
                  <h3 className="fw-bold my-3">{title}</h3>
                  <p className="my-4">
                    {readMore ? description?.substring(0, 308) : description}
                  </p>
                  <Button onClick={() => setReadMore(!readMore)}>
                    {readMore ? `${button_text}` : `${button_text}`}
                  </Button>
                </Col>
              </Row>
            );
          } else {
            return (
              <Row className="product my-5 py-3 d-flex align-items-center g-4">
                <Col md={6}>
                  <LazyLoadImage
                    src={`${image}`}
                    alt="EdStructure"
                    fluid
                    height={`${image}`.height}
                    width="100%"
                  />
                </Col>
                <Col md={6}>
                  <h3 className="fw-bold my-3">{title}</h3>
                  <p className="my-4">
                    {readMore ? description?.substring(0, 308) : description}
                  </p>
                  <Button onClick={() => setReadMore(!readMore)}>
                    {readMore ? `${button_text}` : `${button_text}`}
                  </Button>
                </Col>
              </Row>
            );
          }
        }
      )}
    </Container>
  );
};

export default Products;
