import React from "react";

// imported react-bootstrap styled components
import { Container, Row, Col, Card } from "react-bootstrap";

//lazy loader
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const FeaturedNews = ({ learning_xp, baseURL }) => {
  return (
    <Container className="my-3">
      <div className="text-center">
        <h2 className="my-3">{learning_xp?.title}</h2>
        <p>{learning_xp?.description}</p>
      </div>
      <Row className="my-5 g-4">
        {learning_xp?.learning_description?.map(
          ({ id, title, image, description }) => (
            <Col md={4} key={id}>
              <Card className="border-0">
                <LazyLoadImage
                  variant="top"
                  src={`${image}`}
                  style={{
                    height: "250px",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                />
                <Card.Body className="p-0">
                  <Card.Title as="h5" className="my-3">
                    {title}
                  </Card.Title>
                  <Card.Text as="p">{description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          )
        )}
      </Row>
    </Container>
  );
};

export default FeaturedNews;
