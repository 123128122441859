import React, { useState, useEffect } from "react";

// react-bootstrap components
import { Container, Image, Button, Row, Col } from "react-bootstrap";

// stylesheet
import "../styles/home/banner.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const baseURL = "https://admin.matrixworld.info";

const Resources = () => {
  const [resources, setResources] = useState({});

  useEffect(() => {
    /**
     * resource api
     * @constructor
     * @param {string} baseURL
     * @param {string} endpoint
     * @return {object} resources
     */
    fetch(`${baseURL}/resource_details/`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setResources(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {/* start Resource Page Banner  */}
      <div className="home-banner">
        <LazyLoadImage src={resources?.data?.slider[0]?.image} alt="banner" />
      </div>
      {/* end Resource Page Banner */}

      {/* start Resource Page Content */}
      <Container className="my-5">
        <div>
          {resources?.data?.resource_detail?.map(
            ({ id, title, image, description, button_text }, index) => {
              if (index % 2) {
                return (
                  <Row key={index} id="help-center" className="my-5 g-5">
                    <Col md={6}>
                      <img src={image} alt={title} className="img-fluid" />
                    </Col>
                    <Col md={6}>
                      <h1 style={{ color: "#EBF2F8" }}>{`0${id}`}</h1>
                      <h3>{title}</h3>
                      <p className="my-5">{description}</p>
                      <Button variant="primary">{button_text}</Button>
                    </Col>
                  </Row>
                );
              } else {
                return (
                  <Row
                    key={index}
                    id="help-center"
                    className="my-5 flex-sm-row-reverse g-5"
                  >
                    <Col md={6}>
                      <LazyLoadImage
                        src={image}
                        alt={title}
                        className="img-fluid"
                      />
                    </Col>
                    <Col md={6}>
                      <h1 style={{ color: "#EBF2F8" }}>{`0${id}`}</h1>
                      <h3>{title}</h3>
                      <p className="my-5">{description}</p>
                      <Button variant="primary">{button_text}</Button>
                    </Col>
                  </Row>
                );
              }
            }
          )}
        </div>
      </Container>
      {/* end Resource Page Content */}
    </>
  );
};

export default Resources;
