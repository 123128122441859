import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

//lazy loader for images
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const Solutions = ({ solution }) => {
  return (
    <div>
      <Container id="communities" className="my-5">
        <div className="text-center">
          <h2 className="mt-5">{solution?.title}</h2>
          <p className="my-4">{solution?.short_description}</p>
        </div>
        <Row className="my-5 py-5 g-4">
          {solution?.solutions_description.map(
            ({ title, image, description, button_text }) => (
              <Col md={4}>
                <Card className="shadow h-100 border-0">
                  <LazyLoadImage
                    variant="top"
                    src={`${image}`}
                    roundedCircle={true}
                    style={{ objectFit: "contain" }}
                  />
                  <Card.Body>
                    <Card.Title as="h5" className="my-4 fw-bold">
                      {title}
                    </Card.Title>
                    <Card.Text as="h6">{description}</Card.Text>
                  </Card.Body>
                  <Card.Footer className="border-0 bg-white">
                    <Button variant="primary" className="my-4 px-4">
                      {button_text}
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
            )
          )}
        </Row>
      </Container>
    </div>
  );
};

export default Solutions;
